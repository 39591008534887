<template>
    
    <h1>Admin</h1>
    <h3>Administra emprendedores</h3>
    
    <ul class=" uk-text-left" uk-accordion>
      <li  :class="Object.keys(emprendedores).length > 0 ? '' : 'uk-open'">
        <a href="#" class="uk-accordion-title" style="font-size: 18pt;">0 - Nuevo emprendedor</a>
        <div class="uk-accordion-content">

          <div class="uk-card uk-card-default uk-card-body uk-border-rounded" >

          
            <div uk-grid class="uk-grid-divider">
              <div class="uk-width-1-5@s">
                <p>Datos de contacto</p>
                <p>
                    Nombre: <input type="text" class="uk-input " placeholder="Nombre..." v-model="emprendedor.name" > <br>
                    Teléfono: <input type="tel" class="uk-input " placeholder="De línea..." v-model="emprendedor.tel" > <br>
                    Correo: <input type="email" class="uk-input " placeholder="Correo..." v-model="emprendedor.mail" > <br>
                    Whatsapp: <input type="tel" class="uk-input " placeholder="Con código de país" v-model="emprendedor.wa" > <br>
                    Facebook: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.fb" > <br>
                    Twitter: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.tw" > <br>
                    Instagram: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.ins" > <br>
                </p> 
                <span>CATÁLOGO</span><br>
                <input  class="uk-textarea " v-model="emprendedor.catalogo" placeholder="Liga..."/>
              </div>
              <div class="uk-width-2-5@s">
                <div uk-grid class="uk-grid-divider uk-grid-medium">
                    <div class="uk-width-1-2">
                        <span>MISIÓN</span><br>
                        <textarea rows="10"  class="uk-textarea " v-model="emprendedor.mision" placeholder="Misión..."></textarea>
                    </div>
                    <div class="uk-width-1-2">
                        <span>VISIÓN</span><br>
                        <textarea rows="10"  class="uk-textarea " v-model="emprendedor.vision" placeholder="Visión..."></textarea>
                    </div>
                    <div class="uk-width-1-1">
                        <span>PROPÓSITO</span><br>
                        <textarea rows="10" class="uk-textarea " v-model="emprendedor.proposito" placeholder="Propósito..."></textarea>
                    </div>
                </div>
              </div>
              <div class="uk-width-2-5@s">
                <span>VALORES</span><br>
                <div >
                    Agregar valor: <br>
                    <input class="uk-input uk-form-width-medium uk-inline" placeholder="Valor..." type="text" v-model="nuvalor"> &nbsp; <button @click="addValor(id, true)" class=" uk-inline uk-button uk-button-default uk-border-rounded"><span uk-icon="plus-circle"></span></button>
                </div>
                <ul>
                    <li v-for="(valor, index) in emprendedor.valores" :key="index"> 
                        <span ><input class="uk-input uk-form-width-medium" type="text" v-model="emprendedor.valores[index]" ></span>
                        <button  @click="delete emprendedor.valores[index]" class="uk-button uk-button-primary uk-border-rounded" style="background-color: darkred;"><span uk-icon="trash"></span></button>
                    </li>
                </ul>
              </div>
            </div>
            <br>
            <div uk-grid class="uk-child-width-1-3@s">
                <div>
                    <img v-if="emprendedor.logo"  :src="emprendedor.logo" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'logo', true)" :id="'logo_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Logo" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.feat"  :src="emprendedor.feat" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'feat', true)" :id="'feat_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Destacada" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont1"  :src="emprendedor.cont1" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont1', true)" :id="'cont1_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Contribución 1" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont2"  :src="emprendedor.cont2" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont2', true)" :id="'cont2_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Contribución 2" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont3"  :src="emprendedor.cont3" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont3', true)" :id="'cont3_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Contribución 3" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont4"  :src="emprendedor.cont4" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont4', true)" :id="'cont4_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Contribución 4" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto1"  :src="emprendedor.foto1" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto1', true)" :id="'foto1_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 1" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto2"  :src="emprendedor.foto2" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto2', true)" :id="'foto2_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 2" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto3"  :src="emprendedor.foto3" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto3', true)" :id="'foto3_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 3" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto4"  :src="emprendedor.foto4" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto4', true)" :id="'foto4_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 4" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto5"  :src="emprendedor.foto5" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto5', true)" :id="'foto5_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 5" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto6"  :src="emprendedor.foto6" alt="">
                    <br> 
                    <div class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto6', true)" :id="'foto6_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Subir Foto 6" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <p class="uk-text-right"><button class="uk-button uk-button-large uk-border-rounded uk-button-primary" @click="addEmpr()">Guardar</button></p>
          </div>
        </div>
        <hr>
      </li>
      <li v-for="emprendedor,id in emprendedores">
        <a href="#" class="uk-accordion-title" style="font-size: 18pt;">{{Object.keys(emprendedores).indexOf(id) + 1}} - {{ emprendedor.name }}</a>
        <div class="uk-accordion-content">

          <div class="uk-card uk-card-default uk-card-body uk-border-rounded" >

          
            <div uk-grid class="uk-grid-divider uk-child-width-1-5@s">
              <div>
                <p>Datos de contacto</p>
                <p>
                    Nombre: <input type="text" class="uk-input " placeholder="Nombre..." v-model="emprendedor.name" v-if="editing"><span v-else><b>{{ emprendedor.name }}</b></span> <br>
                    Teléfono: <input type="tel" class="uk-input " placeholder="De línea..." v-model="emprendedor.tel" v-if="editing"><span v-else><b>{{ emprendedor.tel }}</b></span> <br>
                    Correo: <input type="email" class="uk-input " placeholder="Correo..." v-model="emprendedor.mail" v-if="editing"><span v-else><b>{{ emprendedor.mail }}</b></span> <br>
                    Whatsapp: <input type="tel" class="uk-input " placeholder="Con código de país" v-model="emprendedor.wa" v-if="editing"><span v-else><b>{{ emprendedor.wa }}</b></span> <br>
                    Facebook: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.fb" v-if="editing"><span v-else><b>{{ emprendedor.fb }}</b></span> <br>
                    Twitter: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.tw" v-if="editing"><span v-else><b>{{ emprendedor.tw }}</b></span> <br>
                    Instagram: <input type="text" class="uk-input " placeholder="Liga..." v-model="emprendedor.ins" v-if="editing"><span v-else><b>{{ emprendedor.ins }}</b></span> <br>
                </p> <br>
                <span>CATÁLOGO</span><br>
                <input v-if="editing" class="uk-textarea " v-model="emprendedor.catalogo" placeholder="Liga..."/>
                <span v-else><a :href="emprendedor.catalogo">{{ emprendedor.catalogo }}</a></span>
              </div>
              <div class="uk-width-2-5@s">
                <div uk-grid class="uk-grid-divider uk-grid-medium">
                    <div class="uk-width-1-2">
                                
                        <span>MISIÓN</span><br>
                        <textarea v-if="editing" class="uk-textarea " v-model="emprendedor.mision" placeholder="Misión..."></textarea>
                        <span v-else>{{ emprendedor.mision }}</span>
                    </div>
                    <div class="uk-width-1-2">
                                
                        <span>VISIÓN</span><br>
                        <textarea v-if="editing" class="uk-textarea " v-model="emprendedor.vision" placeholder="Visión..."></textarea>
                        <span v-else>{{ emprendedor.vision }}</span>
                    </div>
                    <div class="uk-width-1-1">
                                
                        <span>PROPÓSITO</span><br>
                        <textarea v-if="editing" class="uk-textarea " v-model="emprendedor.proposito" placeholder="Propósito..."></textarea>
                        <span v-else>{{ emprendedor.proposito }}</span>
                    </div>
                </div>
            </div>
              <div>
                <span>VALORES</span><br>
                <div v-if="editing">
                    Agregar valor: <br>
                    <input class="uk-input "  type="text" v-model="nuvalor"> &nbsp; <button @click="addValor(id)" class="uk-button uk-button-default uk-border-rounded"><span uk-icon="plus-circle"></span></button>
                </div>
                <ul>
                    <li v-for="(valor, index) in emprendedor.valores" :key="index"> 
                        <button v-if="editing" @click="delete emprendedor.valores[index]" class="uk-button uk-button-primary uk-border-rounded" style="background-color: darkred;"><span uk-icon="trash"></span></button>
                        <span v-if="editing"><input class="uk-input " type="text" v-model="emprendedor.valores[index]" ></span>
                        <span v-else>{{valor}}</span> 
                    </li>
                </ul>
              </div>
            </div>
            <br>
            <div uk-grid class="uk-child-width-1-3@s">
                <div>
                    <img v-if="emprendedor.logo"  :src="emprendedor.logo" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'logo')" :id="'logo_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Logo" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.feat"  :src="emprendedor.feat" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'feat')" :id="'feat_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Destacada" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont1"  :src="emprendedor.cont1" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont1')" :id="'cont1_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Contribución 1" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont2"  :src="emprendedor.cont2" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont2')" :id="'cont2_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Contribución 2" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont3"  :src="emprendedor.cont3" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont3')" :id="'cont3_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Contribución 3" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.cont4"  :src="emprendedor.cont4" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'cont4')" :id="'cont4_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Contribución 4" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto1"  :src="emprendedor.foto1" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto1')" :id="'foto1_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 1" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto2"  :src="emprendedor.foto2" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto2')" :id="'foto2_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 2" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto3"  :src="emprendedor.foto3" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto3')" :id="'foto3_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 3" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto4"  :src="emprendedor.foto4" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto4')" :id="'foto4_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 4" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto5"  :src="emprendedor.foto5" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto5')" :id="'foto5_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 5" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
                <div>
                    <img v-if="emprendedor.foto6"  :src="emprendedor.foto6" alt="">
                    <br> 
                    <div v-if="editing" class="uk-margin" uk-margin>
                        <div uk-form-custom="target: true">
                            <input @change="uploadImage(id,'foto6')" :id="'foto6_'+id" type="file" accept="image/*"  aria-label="Custom controls">
                            <input class="uk-input uk-form-width-medium" type="text" placeholder="Foto 6" aria-label="Custom controls" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <p class="uk-text-right">
                <button v-if="editing" class="uk-button uk-button-secondary uk-border-rounded" style="background-color: darkred;" @click="delEmpr(id)"> Borrar </button> &nbsp;
                <button v-if="editing" class="uk-button uk-button-secondary uk-border-rounded" @click="editing = !editing"> Cancelar </button> &nbsp;
                <button v-if="editing" class="uk-button uk-button-primary uk-border-rounded" @click="addEmpr(id)"> Guardar </button>
                <button v-else class="uk-button uk-button-text" @click="editing = !editing">  <span uk-icon="pencil"></span> Editar </button>
            </p>
          </div>
        </div>
        <hr>
      </li>
    </ul>

</template>
<style scoped>
a, h1, h3{
    color:white !important
}
</style>
<script>
import { getDatabase, ref as dbref, onValue, update, set } from "firebase/database";
import { getStorage, ref as stref, uploadString, getDownloadURL } from "firebase/storage";
import { getAuth } from 'firebase/auth'
import { onMounted, ref } from "vue";
import { useRouter } from 'vue-router';
export default {
    setup(){
        const router = useRouter()
        const auth = getAuth()
        const loading = ref(true)
        const emprendedores = ref({})
        const emprendedor = ref({
            valores:{},
            fotos:{}
        })
        const id = ref('')
        const editing = ref(false)
        const nuvalor = ref('')
        onMounted(() => {
            // if(!auth.currentUser){
            //     router.push('/')
            // }
            if(!auth.currentUser){
                router.push('/login')
            }
            id.value = String(new Date().getTime())
            onValue(dbref(getDatabase(),'emprendedores/emprendedores'), (s)=>{
                if(s.exists()){
                    emprendedores.value = s.toJSON()
                }
            },(e)=>{
                console.log(e);
                UIkit.notification('Error, check console','danger')
            })
        })
        const delEmpr = (id)=>{
            let ref = 'emprendedores/emprendedores/' + id
            set(dbref(getDatabase(), ref ), null).then(()=>{
                UIkit.notification('Borrado','danger')
            }).catch((e)=>{
                console.log(e);
                UIkit.notification('Error, check console','danger')
            }).finally(()=>{
                loading.value = false
                if(editing.value) editing.value = false
            })
        }
        const addEmpr = (updateId = '')=>{
            loading.value = true

            let myemprededor = {}
            let ref = 'emprendedores/emprendedores/' 
            if(updateId == ''){
                myemprededor = emprendedor.value
                ref += + id.value
            } else {
                myemprededor = emprendedores.value[updateId]
                ref += + updateId
            }
            console.log(ref);
            console.log(myemprededor);
            update(dbref(getDatabase(), ref ), myemprededor).then(()=>{
                emprendedor.value = {valores:{}}
                if(!updateId){
                    id.value = String(new Date().getTime())
                }
            }).catch((e)=>{
                console.log(e);
                UIkit.notification('Error, check console','danger')
            }).finally(()=>{
                loading.value = false
                if(editing.value) editing.value = false
            })
        }
        const addValor = (id, isNew = false)=>{
            if(isNew){
                emprendedor.value.valores[String(new Date().getTime())] = nuvalor.value
            } else {
                if(emprendedores.value[id]){
                    if(emprendedores.value[id].valores){
                        emprendedores.value[id].valores[String(new Date().getTime())] = nuvalor.value
                    } else {
                        emprendedores.value[id].valores = {}
                        emprendedores.value[id].valores[String(new Date().getTime())] = nuvalor.value
                    }
                } else {
                    UIkit.notification('ERROR: Emprendedor no encontrado')
                }
                
                
            }
            nuvalor.value = ''
        }
        const uploadImage = (emprId, imgName, isNew = false)=>{
            if(isNew){
                emprId = id.value
            }


            let foto = document.getElementById(imgName+'_'+emprId).files[0]
            let img_data_url = ''    
            if (FileReader) {
                let fr = new FileReader();
                var img = document.createElement("img");
                fr.onload = function (e) {
                    img.onload = function () {
                        // Dynamically create a canvas element
                        var canvas = document.createElement("canvas");

                        // var canvas = document.getElementById("canvas");
                        var ctx = canvas.getContext("2d");

                        // Actual resizing
                        var MAX_WIDTH = 720;
                        var MAX_HEIGHT = 720;

                        var width = img.width;
                        var height = img.height;

                        // Change the resizing logic
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height = height * (MAX_WIDTH / width);
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width = width * (MAX_HEIGHT / height);
                                height = MAX_HEIGHT;
                            }
                        }
                        var canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);


                        var dataurl = canvas.toDataURL(foto.type);
                        img_data_url = dataurl
                        uploadString(stref(getStorage(), 'emprendedores/emprendedores/'+emprId + '/' + imgName + '.png'), img_data_url, 'data_url').then(()=>{
                            getDownloadURL(stref(getStorage(), 'emprendedores/emprendedores/'+emprId + '/' + imgName + '.png')).then((res)=>{
                                let updateObj = {}
                                updateObj[imgName] = res
                                update(dbref(getDatabase(),'emprendedores/emprendedores/'+emprId),updateObj).then(()=>{
                                    UIkit.notification('Imagen cargada correctamente','success')
                                }).catch((e)=>{
                                    console.log(e);
                                })
                            })
                        })
                    }
                    img.src = e.target.result;
                }
                fr.readAsDataURL(foto);
            } else {
                UIkit.notification('Por favor intenta en otro dispositivo','danger')
                UIkit.notification('Este dispositivo no soporta leido de archivos','danger')
                return
            }

        }

        return {
            id,
            emprendedor,
            emprendedores,
            editing,
            nuvalor,
            loading,
            addEmpr,
            delEmpr,
            addValor,
            uploadImage,
        }
    }
}
</script>

<style>

</style>